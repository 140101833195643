import styles from '../assets/styles/components/loading';
import {CircularProgress, createTheme, MuiThemeProvider} from "@material-ui/core";

export default function Loading() {
    const classes =  styles();

    const theme = createTheme({
        palette: {
            primary: {
                main: '#FF7121',
            },
            secondary: {
                main: '#ffffff'
            }
        }
    });

    return (
        <MuiThemeProvider theme={theme}>
            <div className={classes.backdrop}>
                <div className={classes.container}>
                    <p className={classes.title}>Realizando a Assinatura</p>
                    <CircularProgress color="primary" />
                </div>
            </div>
        </MuiThemeProvider>
    )
}
