import {Button, Card, CircularProgress, useMediaQuery} from "@material-ui/core";
import styles from '../assets/styles/components/cardResume';
import clsx from "clsx";
import {useEffect, useState} from "react";
import {FaCheck, FaEyeSlash} from "react-icons/fa";
import {getNumberOfMonths, prices} from "../helpers/resume.helpers";
import {FaEye} from 'react-icons/fa';
import { FontSize } from "@icon-park/react";

type TotalProps = {
    prices: any;
    plan: any;
    numberOfMonths: number;
    byMonth: boolean;
    withImplement: boolean;
}
export function getTotal({prices,plan,numberOfMonths,byMonth,withImplement}: TotalProps) {
    let total = (plan.user * prices.user)
        + (plan.whatsAppNumber * prices.whatsAppNumber);
    if (!byMonth) {
        total = total * numberOfMonths;
    }
    if (withImplement) {
        total = total + prices.implement[plan.period]?.amount;
    }
    return total;
}

export default function CardResume(props: any) {
    const {plan, reduced = false, startOpened = true, alwaysOnTop = false} = props;
    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = styles();
    const [showDetails, setShowDetails] = useState(startOpened);
    const numberOfMonths = getNumberOfMonths(plan.period);
    const periodLabel: any = {
        monthly: {
            label: 'Mensal',
            labelPeriod: 'mês'
        },
        semester: {
            label: 'Semestral',
            labelPeriod: 'semestre'
        },
        yearly: {
            label: 'Anual',
            labelPeriod: 'ano'
        },
    };

    useEffect(() => {
        setShowDetails(startOpened)
    }, [])

    const MonthlyTotal = () => (
        <>
            <div className={clsx(classes.line)} style={{margin: '10px 0 0', alignItems: 'flex-end'}}>
            {/* <span className={classes.itemTitle} style={{fontSize: '1rem'}}>
                Total 1º mês
            </span>
                <span className={classes.itemPrice}
                      style={{fontSize: '1rem'}}>
                  R$ {getTotal({prices, plan, numberOfMonths, byMonth: false, withImplement: true})}
            </span> */}
            </div>
            <div className={clsx(classes.line)} style={{margin: '0', alignItems: 'center', justifyContent: 'center'}}>
                
                <span className={classes.itemPrice}
                      style={{fontSize: '1.15rem', textAlign: 'center'}}>
                      R$ {getTotal({prices, plan, numberOfMonths, byMonth: false, withImplement: false})}/mês
                </span>
            </div>
        </>
    );

    const SemesterTotal = () => (
        <>
            <div className={clsx(classes.line)} style={{margin: '10px 0 0', alignItems: 'flex-end'}}>
                <span className={classes.itemTitle} style={{fontSize: '1rem'}}>
                    Total por semestre
                </span>
                <span className={classes.itemPrice} style={{fontSize: '1rem'}}>
                  R$ {getTotal({prices, plan, numberOfMonths, byMonth: false, withImplement: true})}
                </span>
            </div>
            <div className={clsx(classes.line)} style={{margin: '0', alignItems: 'center'}}>
                <span className={classes.itemTitle} style={{fontSize: '.95rem'}}>
                    (Em até 3x no cartão ou boleto)
                </span>
            </div>
        </>
    );

    const YearlyTotal = () => (
        <>
            <div className={clsx(classes.line)} style={{margin: '10px 0 0', alignItems: 'center'}}>
                <span className={classes.itemTitle} style={{fontSize: '1rem'}}>
                    Total por ano
                </span>
                <span className={classes.itemPrice} style={{fontSize: '1rem'}}>
                  R$ {getTotal({prices, plan, numberOfMonths, byMonth: false, withImplement: true})}
                </span>
            </div>
            <div className={clsx(classes.line)} style={{margin: '0', alignItems: 'center'}}>
                <span className={classes.itemTitle} style={{fontSize: '.95rem'}}>
                    (Em até 3x no cartão ou boleto)
                </span>
            </div>
        </>
    );

    function reducedVersion() {
        function getImplementMessage() {
            let message = <small className={classes.implementMessage}>+ Implementação de R$ {prices.implement[plan.period]?.amount} (pagamento único)</small>;
            if (plan.period === 'semester') {
                message = <small className={classes.implementMessage} style={{ color: 'orangered' }}>50% off na taxa de implementação</small>
            } else if (plan.period === 'yearly') {
                message = <small className={classes.implementMessage} style={{ color: 'orangered' }}>100% off na taxa de implementação</small>
            }
            return message;
        }

        return (
            isMobile ? (
                <>
                    <div className={classes.reducedContainer}>
                        <p>
                            Resumo da sua fatura
                        </p>
                        <h3>
                            R$ {getTotal({prices, plan, numberOfMonths, byMonth: false, withImplement: false})}
                            {'/'}
                            {periodLabel[plan.period]?.labelPeriod}
                        </h3>
                        {getImplementMessage()}
                        {//isMobile && (
                            // <div>
                            //     <Button style={{textTransform: 'lowercase', fontSize: '.8rem'}} onClick={() => setShowDetails(!showDetails)}>
                            //         {showDetails ? <FaEyeSlash style={{marginRight: 5}}/> : <FaEye style={{marginRight: 5}}/>}
                            //         {showDetails ? 'Ocultar' : 'Exibir'} detalhes
                            //     </Button>
                            // </div>
                         //)
                         }
                    </div>
                    {showDetails && isMobile}
                </>
            ): (
                <>
                    <div className={classes.reducedDesktopContainer}>
                        <p>
                            Resumo da Assinatura
                        </p>
                        <div>
                            <p style={{marginBottom: 10}}>
                                Plano {periodLabel[plan.period]?.label}
                            </p>
                            <h3>
                                {/* R$ {getTotal({prices, plan, numberOfMonths, byMonth: false, withImplement: true})} */}
                                R$ {getTotal({prices, plan, numberOfMonths, byMonth: false, withImplement: false})}/mês
                            </h3>
                            <h3>
                                <span className={classes.itemTitle} style={{fontSize: '13px', textAlign: 'center', width: '100%'}}>
                                    + Implementação de {prices.implement[plan.period]?.amount > 0 ? `R$ ${prices.implement[plan.period]?.amount}` : 'BÔNUS'} (pagamento único)
                                </span>
                            </h3>
                        </div>
                    </div>
                </>
            )
        )
    }

    function completeVersion(withButton = true) {
        return (
            <Card className={classes.container} style={alwaysOnTop ? { position: 'sticky', top: 20 } : {}}>
                <h3 className={classes.title}>Resumo da Assinatura</h3>
                <h3 className={classes.title} style={{fontSize: '.98rem', margin: '-5px 0 20px'}}>
                    <FaCheck color="var(--primary)" style={{transform: 'translate(-5px, 1px)'}} />
                    Plano {periodLabel[plan.period]?.label}
                </h3>
                {/* {[
                    {key: 'whatsAppNumber', title: 'Números de WhastApp'},
                    {key: 'user', title: 'Usuários'},
                ].map(item => (
                    <div key={item.key} className={classes.line}>
                    <span className={classes.itemTitle}>
                        <small>{plan[item.key]}x</small> {item.title}
                    </span>
                        <span className={classes.itemPrice}>
                        R$ {plan[item.key] * prices[item.key] * numberOfMonths}
                            <small>/{periodLabel[plan.period]?.labelPeriod}</small>
                    </span>
                    </div>
                ))} */}
                <div>
                {
                    plan.period === 'yearly'
                        ? <YearlyTotal />
                        : plan.period === 'semester'
                        ? <SemesterTotal />
                        : <MonthlyTotal />
                }
                </div>
                <div className={clsx(classes.line)} style={{margin: '5px 0 0'}}>
                <span className={classes.itemTitle} style={{fontSize: '13px', textAlign: 'center', width: '100%'}}>
                    + Implementação de {prices.implement[plan.period]?.amount > 0 ? `R$ ${prices.implement[plan.period]?.amount}` : 'BÔNUS'} (pagamento único)
                </span>
                </div>
                
                {withButton && (
                    props.submitting ? (
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 20}}>
                            <CircularProgress size={25}/>
                            <p style={{color: 'var(--primary)', margin: '0 0 0 10px', textAlign: 'center'}}>
                                Realizando a assinatura
                            </p>
                        </div>

                    ) : (
                        <Button variant="contained"
                                fullWidth color="primary"
                                onClick={() => props.handleSubmit()}
                                style={{backgroundColor: 'var(--green)', color: '#fff', marginTop: 25}}>
                            Assinar agora
                        </Button>
                    )
                )}
            </Card>
        )
    }

    return reduced ? reducedVersion() : completeVersion();
}
