export const isValidCpf = (cpf: string): boolean => {
    if (!cpf) {
        return false;
    }
    const cpfTransform = cpf.replace(/\D/g, '');
    const blockList = [
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
    ];

    if (cpfTransform.length !== 11 || blockList.includes(cpfTransform)) {
        return false;
    }

    const d1 = parseInt(cpfTransform.substring(0, 1));
    const d2 = parseInt(cpfTransform.substring(1, 2));
    const d3 = parseInt(cpfTransform.substring(2, 3));
    const d4 = parseInt(cpfTransform.substring(3, 4));
    const d5 = parseInt(cpfTransform.substring(4, 5));
    const d6 = parseInt(cpfTransform.substring(5, 6));
    const d7 = parseInt(cpfTransform.substring(6, 7));
    const d8 = parseInt(cpfTransform.substring(7, 8));
    const d9 = parseInt(cpfTransform.substring(8, 9));
    const vd1 = parseInt(cpfTransform.substring(9, 10));
    const vd2 = parseInt(cpfTransform.substring(10));

    const sunVD1 =
        d1 * 10 +
        d2 * 9 +
        d3 * 8 +
        d4 * 7 +
        d5 * 6 +
        d6 * 5 +
        d7 * 4 +
        d8 * 3 +
        d9 * 2;
    const restVD1 = sunVD1 % 11;
    const resultVD1 = restVD1 < 2 ? 0 : 11 - restVD1;

    if (resultVD1 !== vd1) {
        return false;
    }

    const sunVD2 =
        d1 * 11 +
        d2 * 10 +
        d3 * 9 +
        d4 * 8 +
        d5 * 7 +
        d6 * 6 +
        d7 * 5 +
        d8 * 4 +
        d9 * 3 +
        resultVD1 * 2;
    const restVD2 = sunVD2 % 11;
    const resultVD2 = restVD2 < 2 ? 0 : 11 - restVD2;

    return resultVD2 === vd2;
}

export const isValidCnpj = (cnpj: string): boolean => {
    const formattedCnpj = cnpj.replace(/\D/g, '');

    if (formattedCnpj === '' || formattedCnpj.length !== 14) {
        return false;
    }

    // Elimina CNPJs invalidos conhecidos
    if (formattedCnpj === "00000000000000" ||
        formattedCnpj === "11111111111111" ||
        formattedCnpj === "22222222222222" ||
        formattedCnpj === "33333333333333" ||
        formattedCnpj === "44444444444444" ||
        formattedCnpj === "55555555555555" ||
        formattedCnpj === "66666666666666" ||
        formattedCnpj === "77777777777777" ||
        formattedCnpj === "88888888888888" ||
        formattedCnpj === "99999999999999") {
        return false;
    }

    // Valida DVs
    let cnpjLength: number = formattedCnpj.length - 2
    let cnpjNumbers: string = formattedCnpj.substring(0, cnpjLength);
    let cnpjDigits: string = formattedCnpj.substring(cnpjLength);
    let sum: number = 0;
    let position: number = cnpjLength - 7;
    for (let i = cnpjLength; i >= 1; i--) {
        sum += parseInt(cnpjNumbers.charAt(cnpjLength - i)) * position--;
        if (position < 2) {
            position = 9;
        }
    }
    let result = (sum % 11 < 2) ? 0 : (11 - sum % 11);
    if (result !== parseInt(cnpjDigits.charAt(0))) {
        return false;
    }

    cnpjLength = cnpjLength + 1;
    cnpjNumbers = formattedCnpj.substring(0, cnpjLength);
    sum = 0;
    position = cnpjLength - 7;
    for (let i = cnpjLength; i >= 1; i--) {
        sum += parseInt(cnpjNumbers.charAt(cnpjLength - i)) * position--;
        if (position < 2) {
            position = 9;
        }
    }
    result = (sum % 11 < 2) ? 0 : (11 - sum % 11);

    return result === parseInt(cnpjDigits.charAt(1));
}
