import styles from '../../../assets/styles/components/posCheckoutCreateAccount';
import stylesBase from '../../../assets/styles/components/base';
import stylesSteps from '../../../assets/styles/components/stepsCheckout';
import  { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../pages/PosCheckout";
import { usePostCheckout } from "../../../hooks/PosCheckoutContext";
import { ButtonWithIcon } from "../buttonWithIcon";
import axios from 'axios';
import { api } from '../../../services/api';

const URL_FRONT = "https://app.ihelpchat.com/login?auth=";


export default function CreateValidatePhone ({field, form, ...props}: any) {
    const classes = styles();
    const classesBase = stylesBase();
    const classesSteps = stylesSteps();
    const [loading, setLoading] = useState(false);
    const [valueOne, setValueOne] = useState("");
    const [valueTwo, setValueTwo] = useState("");
    const [valueThree, setValueThree] = useState("");
    const [valueFour, setValueFour] = useState("");
    const {handleError, handleStep} = usePostCheckout();

    const valueOneRef = useRef<any>(null);
    const valueTwoRef = useRef<any>(null);
    const valueThreeRef = useRef<any>(null);
    const valueFourRef = useRef<any>(null);

    useEffect(() => {
        handleStep(4);
    }, [])

    useEffect(() => {
        if(valueOne && valueTwo && valueThree && valueFour){
            (async function(){ 
                await validateCode();
            })();
        }
    }, [valueFour])

    function onlyNumbers(str:string) {
        return /^[0-9]+$/.test(str);
      }

    async function validateCode(){
        setLoading(true);
        const codeComplete = `${valueOne}${valueTwo}${valueThree}${valueFour}`;
        const {userIdRef} = form.values;

        try {
           const result =  await api.get(`${API_URL}/validate/email-login/${userIdRef}/?code=${codeComplete}`);

           const jwt = result.data.mensagem;

           window.open(`${URL_FRONT}${jwt}&idRef=${userIdRef}`, '_self')


        }catch(err){
            resetForm();
            if(axios.isAxiosError(err)){
                handleError(err.response?.data.mensagem);
            }
            else {
                handleError("Código inválido tente novamente.");
            }
        }finally{   
            setLoading(false);
        }
    }

    function resetForm(){
        setValueOne("");
        setValueTwo("");
        setValueThree("");
        setValueFour("");
    }

    function handlePaste(event:any){
        event.clipboardData.items[0].getAsString((text:any) => {
            if(text.length === 4 && onlyNumbers(text.toString())) {
                const [one, two,three,four] = text.split("");

                valueOneRef.current!.value = one;
                 valueTwoRef.current!.value = two;
                 valueThreeRef.current!.value = three;
                 valueFourRef.current!.value = four;

                setValueOne(one);
                setValueTwo(two);
                setValueThree(three);
                setValueFour(four);

                
            }
        })
      }

    function  handleChange(e:any){
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");

        if(fieldIndex == 1) setValueOne(value);
        if(fieldIndex == 2) setValueTwo(value);
        if(fieldIndex == 3) setValueThree(value);
        if(fieldIndex == 4) setValueFour(value);
      
        let fieldIntIndex = parseInt(fieldIndex, 10);
      
        if (value.length >= maxLength && e.which !== 8) {
      
          if (fieldIntIndex < 4) {
            const nextfield:any = document.querySelector(
              `input[name=value-${fieldIntIndex + 1}]`
            );
      
            if (nextfield !== null) {
              nextfield.focus();
            }
          }
        } else if(value.length === 0 && e.which === 8) {
                const nextfield:any = document.querySelector(
                  `input[name=value-${fieldIntIndex - 1}]`
                );
          
                if (nextfield !== null) {
                  nextfield.focus();
                }
        }
      };

  return (
        <div className={classesSteps.wrapperStep} onPaste={handlePaste}>
            <div className={classes.containerDialog} style={{margin: "0 auto"}}>
                <div className={classes.containerHeader}>
                    <img src="https://app.ihelpchat.com/ihelp-angular/assets/images/logotipo_color.png" alt="IHelp" className={classes.logo}/>
                </div>
            </div>

            <div className={classes.containerForm} style={{display:"flex", alignItems:"center", textAlign:"center"}}>
                 <p className={classesBase.textLarge} style={{fontWeight:400, fontSize:24, marginBottom:30}}>Verifique a sua conta</p>
                 <p className={classesBase.textMedium} style={{ marginTop:2, fontSize:20}}>Acesse o seu WhatsApp ou sua caixa de entrada no E-mail<br /> </p>
                 <p className={classesBase.textMedium} style={{fontWeight:600, marginTop:2, fontSize:20}}>{form.values.useremail} ou {form.values.whatsapp}.</p>

                 <p className={classesBase.textMedium} style={{fontWeight:600, marginTop:50, fontSize:20}}>Insira o código que você recebeu</p>

                 <div className={classesSteps.wrapperCodeContainer} style={{display:"flex", alignItems:"center"}}>

                    <div className={classesSteps.wrapperCode}>
                        <input type="text" name="value-1" className={classesSteps.inputCode} maxLength={1} ref={valueOneRef} onKeyUp={handleChange} required />
                    </div>

                    <div className={classesSteps.wrapperCode}>
                        <input type="text" name="value-2"  className={classesSteps.inputCode} maxLength={1}  ref={valueTwoRef} onKeyUp={handleChange} required />
                    </div>

                    <div className={classesSteps.wrapperCode}>
                        <input type="text" name="value-3"  className={classesSteps.inputCode} maxLength={1}  ref={valueThreeRef} onKeyUp={handleChange}required />
                    </div>

                    <div className={classesSteps.wrapperCode}>
                        <input type="text" name="value-4"  className={classesSteps.inputCode} maxLength={1} ref={valueFourRef} onKeyUp={handleChange} required/>
                    </div>
                 </div>
            </div>

            <div className={classesSteps.wrapperFooter} style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                <ButtonWithIcon 
                    style={{width:"50%"}}
                    loadingButton={loading}
                    disabled={loading}
                    label="PRÓXIMO"
                    onClick={validateCode}
                />
                {form.values.whatsapp && <p className={classesBase.textMedium} style={{fontWeight:300, textAlign: "center", color:"#6D6D6D"}}> Gostaria de verificar sua conta por WhatsApp? <a href="https://api.whatsapp.com/send?phone=551730422307&text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20com%20o%20%23onboarding" className={classesSteps.linkCustom} style={{color:"#6D6D6D"}}>Clique aqui</a></p>}
            </div>

    </div>
  )
}