import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  // Steps
  wrapperStep: {
    display: "flex",
    alignItems: "center",
    // alignItems: "flex-start",
    flexDirection: "column",
  },

  wrapperFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 15,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  wrapperProgress: {
    width: 165,
    height: 30,
    padding: 5,
    borderRadius: 3,
    marginTop: 10,
    marginBottom: 10,

    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 15,
    },
  },

  progress: {
    background: "#ddd",
    borderRadius: 8,
    width: "100%",
    height: 10,
  },

  barProgress: {
    height: 10,
    borderRadius: 8,
    backgroundColor: "#74CFC0",
  },

  wrapperCodeContainer: {
    display: "flex",
    gap: 20,
    marginTop: 30,
    marginBottom: 30,
  },

  wrapperCode: {
    width: 64,
    height: 64,
    background: "#EAEAEA",
    borderRadius: 5,
    padding: 5,

    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
    },
  },
  inputCode: {
    border: 0,
    textAlign: "center",
    borderBottomWidth: 2,
    color: "#999",
    outline: "none",
    borderBottomColor: "#333",
    background: "transparent",
    fontFamily: "Inter, sans-serif",
    width: "100%",
    height: "100%",
    fontSize: 40,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
  },
  selectCustom: {
    width: "100%",
    height: "100%",
    border: 0,
    background: "transparent",
    fontSize: 16,
    padding: 10,
    outline: "none",
  },
  linkCustom: {
    color: "#6D6D6D",
    textDecoration: "underline",
  },
}));
