import React, {useState, useEffect} from 'react';
import styles from '../assets/styles/components/payment';
import {
    FormControl, FormHelperText,
    Grid,
    InputLabel, MenuItem,
    OutlinedInput, Select,
    TextField,
    useMediaQuery
} from "@material-ui/core";
import TextMaskCustom from "../components/textMaskCustom";
import {FaCreditCard, FaBarcode} from 'react-icons/fa';
import clsx from "clsx";
import Cards from 'react-credit-cards';
import "react-credit-cards/es/styles-compiled.css";
import {getError} from "../helpers/validate.helpers";

let valueChangeTimeout: any;

interface PaymentProps {
    errors: any,
    valueChange: (value: any) => any,
    touched: string[],
    handleTouched: (value: any) => any,
    amount: number,
    planPeriod: string,
    submitted: boolean
}
export default function PaymentSection(props: PaymentProps) {
    const {valueChange, errors, amount, planPeriod, touched, handleTouched, submitted} = props;
    const isMobile = useMediaQuery('(max-width:600px)');
    const classes = styles();
    const [values, setValues] = useState({
        paymentMethod: 'credit_card',
        creditCardNumber: '',
        creditCardName: '',
        creditCardExpiry: '',
        creditCardCvc: '',
        inputFocused: '',
        installments: 1
    });

    useEffect(() => {
        clearTimeout(valueChangeTimeout);
        valueChangeTimeout = setTimeout(() => {
            valueChange(values);
        }, 200);
    }, [values]);

    function handleChange(name: string, value: any) {
        setValues({...values, [name]: value});
    }

    function isStatus(status: 'invalid'|'valid', name: string): boolean {
        if (status === 'valid') {
            return touched.includes(name) && !getError(errors, name);
        } else if (status === 'invalid') {
            return (submitted || touched.includes(name)) && getError(errors, name);
        }
        return false;
    }

    function getInstallmentsOptions(numberOfInstallments: number) {
        const options = [];
        for (let i = 1; i <= numberOfInstallments; i++) {
            i === 1
            ? options.push(<MenuItem value={i}>À Vista</MenuItem>)
            : options.push(<MenuItem value={i}>{i}x de  R$ {(amount/i).toFixed(2)} - sem juros</MenuItem>)
        }
        return options;
    }

    function SectionCreditCard(values: any, handleChange: any, errors: any, isMobile = false) {
        return (
            <Grid container spacing={2}>
                <Grid item md={7} xs={12}>
                    {/*<TextField*/}
                    {/*    fullWidth*/}
                    {/*    label="Número do Cartão"*/}
                    {/*    placeholder="0000 0000 0000 0000"*/}
                    {/*    style={{marginBottom: 15}}*/}
                    {/*    size="small"*/}
                    {/*    className={`${isStatus('valid', 'payment.creditCardNumber') ? 'input-valid' : ''}`}*/}
                    {/*    onKeyPressCapture={() => handleTouched('payment.creditCardNumber')}*/}
                    {/*    onBlur={() => handleTouched('payment.creditCardNumber')}*/}
                    {/*    error={isStatus('invalid', 'payment.creditCardNumber')}*/}
                    {/*    helperText={isStatus('invalid', 'payment.creditCardNumber') && getError(errors, 'payment.creditCardNumber')?.message}*/}
                    {/*    value={values.creditCardNumber}*/}
                    {/*    onChange={e => handleChange('creditCardNumber', e.target.value)}*/}
                    {/*    variant="outlined"*/}
                    {/*/>*/}
                    <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel>Número do Cartão</InputLabel>
                        <OutlinedInput
                            className={`${isStatus('valid', 'payment.creditCardNumber') ? 'input-valid' : ''}`}
                            onKeyPressCapture={() => handleTouched('payment.creditCardNumber')}
                            onBlur={() => handleTouched('payment.creditCardNumber')}
                            error={isStatus('invalid', 'payment.creditCardNumber')}
                            value={values.creditCardNumber}
                            onChange={e => handleChange('creditCardNumber', e.target.value)}
                            onFocus={() => handleChange('inputFocused', 'number')}
                            style={{marginBottom: isStatus('invalid', 'payment.creditCardNumber') ? 0 : 15}}
                            inputProps={{
                                mask: [/[0-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
                                placeholder: '0000 0000 0000 0000'
                            }}
                            inputComponent={TextMaskCustom}
                        />
                        {isStatus('invalid', 'payment.creditCardNumber') && (
                            <FormHelperText error>{getError(errors, 'payment.creditCardNumber')?.message}</FormHelperText>
                        )}
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Nome do Titular"
                        style={{marginBottom: 15}}
                        size="small"
                        className={`${isStatus('valid', 'payment.creditCardName') ? 'input-valid' : ''}`}
                        onKeyPressCapture={() => handleTouched('payment.creditCardName')}
                        onBlur={() => handleTouched('payment.creditCardName')}
                        error={isStatus('invalid', 'payment.creditCardName')}
                        helperText={isStatus('invalid', 'payment.creditCardName') && getError(errors, 'payment.creditCardName')?.message}
                        value={values.creditCardName}
                        onChange={e => {
                            const value = e.target.value.toUpperCase();
                            handleChange('creditCardName', value);
                        }}
                        variant="outlined"
                        placeholder="NOME DO TITULAR"
                    />
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel>Validade</InputLabel>
                                <OutlinedInput
                                    value={values.creditCardExpiry}
                                    onChange={e => handleChange('creditCardExpiry', e.target.value)}
                                    onFocus={() => handleChange('inputFocused', 'expiry')}
                                    className={`${isStatus('valid', 'payment.creditCardExpiry') ? 'input-valid' : ''}`}
                                    onKeyPressCapture={() => handleTouched('payment.creditCardExpiry')}
                                    onBlur={() => handleTouched('payment.creditCardExpiry')}
                                    error={isStatus('invalid', 'payment.creditCardExpiry')}
                                    inputProps={{
                                        mask: [/[0-9]/, /\d/, '/', /\d/, /\d/,  /\d/, /\d/],
                                        placeholder: '00/0000'
                                    }}
                                    inputComponent={TextMaskCustom}
                                />
                                {isStatus('invalid', 'payment.creditCardExpiry') && (
                                    <FormHelperText error>{getError(errors, 'payment.creditCardExpiry')?.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel>Código de Segurança</InputLabel>
                                <OutlinedInput
                                    value={values.creditCardCvc}
                                    onChange={e => handleChange('creditCardCvc', e.target.value)}
                                    onFocus={() => handleChange('inputFocused', 'cvc')}
                                    className={`${isStatus('valid', 'payment.creditCardCvc') ? 'input-valid' : ''}`}
                                    onKeyPressCapture={() => handleTouched('payment.creditCardCvc')}
                                    onBlur={() => handleTouched('payment.creditCardCvc')}
                                    error={isStatus('invalid', 'payment.creditCardCvc')}
                                    inputProps={{
                                        mask: [/[0-9]/, /\d/, /\d/],
                                        placeholder: '000'
                                    }}
                                    inputComponent={TextMaskCustom}
                                />
                                {isStatus('invalid', 'payment.creditCardCvc') && (
                                    <FormHelperText error>{getError(errors, 'payment.creditCardCvc')?.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    {planPeriod !== 'monthly' && (
                        <Grid item md={12} xs={12}>
                            <FormControl variant="outlined" size="small" fullWidth
                                         style={{marginTop: 15}}>
                                <InputLabel>Parcelamento</InputLabel>
                                <Select
                                    value={values.installments}
                                    onChange={(e) => handleChange('installments', e.target.value)}
                                >
                                    {getInstallmentsOptions(3)}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
                {!isMobile && (
                    <Grid item md={5} xs={12}>
                        <Cards
                            locale={{ valid: 'Validade'}}
                            placeholders={{name: 'SEU NOME AQUI'}}
                            number={values.creditCardNumber}
                            name={values.creditCardName}
                            expiry={values.creditCardExpiry}
                            cvc={values.creditCardCvc}
                            focused={values.inputFocused}
                            preview
                        />
                    </Grid>
                )}
            </Grid>
        );
    }

    function SectionBillet(classes: any) {
        return (
            <>
                <h3 className={classes.billetTitle}>Atente-se aos detalhes:</h3>
                <ul className={classes.billetItems}>
                    <li>Boleto (somente à vista)</li>
                    <li>Pagamentos com Boleto Bancário levam até 3 dias úteis para serem compensados e então terem os produtos liberados</li>
                    <li>Atente-se ao vencimento do boleto. Você pode pagar o boleto em qualquer banco ou casa lotérica até o dia do vencimento</li>
                    <li>Depois do pagamento, verifique seu e-mail para receber os dados de acesso ao produto (verifique também a caixa de SPAM)</li>
                </ul>
            </>
        );
    }

    return (
        <Grid container justifyContent="space-between" spacing={2} style={{padding: '0 10px 10px'}}>
            <div className={classes.paymentMethodContainer}>
                {[
                    {key: 'credit_card', name: <>Cartão de<br/>Crédito</>, icon: <FaCreditCard />},
                    {key: 'billet', name: <>Boleto</>, icon: <FaBarcode />},
                ].map(method => (
                    <div key={`method-${method.key}`}
                         className={clsx(classes.paymentMethod, {'active': values.paymentMethod === method.key})}
                         onClick={() => handleChange('paymentMethod', method.key)}>
                        {method.icon}
                        <span>{method.name}</span>
                    </div>
                ))}
            </div>
            <div className={classes.tabContainer}>
                {values.paymentMethod === 'credit_card'
                    ? SectionCreditCard(values, handleChange, errors, isMobile)
                    : SectionBillet(classes) }
            </div>
        </Grid>
    )
}


