import React from 'react';
import './App.css';
import {createTheme, MuiThemeProvider} from "@material-ui/core";
import Checkout from "./pages/Checkout";
import PosCheckout from './pages/PosCheckout';
import PosCheckoutCreateAccount from './pages/PosCheckoutCreateAccount';
import { PostCheckoutProvider } from './hooks/PosCheckoutContext';

function App() {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#898989',
            },
            secondary: {
                main: '#ffffff'
            }
        }
    });
    let confirmPayment = window.location.search === '?checkout' ||  window.location.search.includes("sub")
    let checkout = window.location.search === '?checkout_success';

    return (
        <MuiThemeProvider theme={theme}>
            {!confirmPayment && <Checkout/>}
            {confirmPayment &&  <PosCheckout /> }
            {checkout && (
                <PostCheckoutProvider>
                    <PosCheckoutCreateAccount />
                </PostCheckoutProvider>
            )}
        </MuiThemeProvider>
    );
}

export default App;
