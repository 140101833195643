import {makeStyles, Theme} from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
    contactContainer: {
        padding: '50px 0',
        [theme.breakpoints.down('sm')]: {
            padding: '30px 0 50px',
        }
    },
    sendBtn: {
        minWidth: 250,
        marginTop: 20,
        marginBottom: 30,
        textTransform: "lowercase",
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 30,
        }
    },
    radio: {
        border: '1px solid var(--dark2)',
        width: '100%',
        borderRadius: 6,
        margin: 0,
        marginBottom: 10,
        '& p': {
            margin: '5px 0',
            '& > small': {
                lineHeight: '95%',
                display: 'block',
                fontSize: '0.7rem'
            }
        }
    },
    counterContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        '& label, label span': {
            width: '100%',
            textAlign: 'center',
            minWidth: '100px !important'
        },
        '& fieldset': {
            minInlineSize: 'auto',
        }
    },
    labelUserCount: {
        fontSize: '12px',
        textAlign: 'center',
        margin: '-10px 0 13px 0',
        width: '100%',
        color: '#818181'
    }
}))
