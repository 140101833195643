import React, { useEffect } from "react"
import stylesSteps from '../../../assets/styles/components/stepsCheckout';
import { usePostCheckout } from "../../../hooks/PosCheckoutContext";

export default function ControlSteps ({totalSteps, currentStep}: any) {
    const {handleStep} = usePostCheckout();
    const classesSteps = stylesSteps();
    const value = ((currentStep * 100 / 100) / totalSteps) * 100;
    useEffect(()=>{
        handleStep(currentStep)
    }, [currentStep])

  return (
        <div className={classesSteps.wrapperProgress}>
            <div className={classesSteps.progress}>
                <div className={classesSteps.barProgress} style={{width:`${value}%`}}></div>
            </div>
        </div>
  )
}
