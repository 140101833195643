import React, {useEffect, useState} from 'react';
import {
    FormControl,
    FormHelperText,
    Grid, IconButton, InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {formatJustNumber, getError} from "../helpers/validate.helpers";
import {getAddressByCep} from "../services/address.service";
import {STATES} from "../helpers/state.helpers";
import {FaUser, FaEnvelope, FaLock, FaPhone, FaMapMarkerAlt, FaSearch} from 'react-icons/fa';
import Toastify from 'toastify-js';
import {useLocation} from 'react-router-dom';

let valueChangeTimeout: any;

interface PersonalProps {
    errors: any,
    valueChange: (value: any) => any,
    touched: string[],
    handleTouched: (value: any) => any,
    submitted: boolean
}
export default function PersonalSection(props: PersonalProps) {
    const {valueChange, errors, touched, handleTouched, submitted} = props;
    const location = useLocation();
    const [values, setValues] = useState({
        name: '',
        email: '',
        cpfCnpj: '',
        phoneNumber: '',
        zipcode: '',
        street: '',
        neighborhood: '',
        city: '',
        state: '',
        additional_details: '',
        number: '',
        phoneDDD: ''
    });
    const [showAddress, setShowAddress] = useState(false);

    useEffect(() => {
        clearTimeout(valueChangeTimeout);
        valueChangeTimeout = setTimeout(() => {
            valueChange(values);
        }, 200);
    }, [values]);

    useEffect(() => {
        getAddress();
    }, [values.zipcode]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const phone = queryParams.get('phone')?.split(')')?.map(splitItem => formatJustNumber(splitItem)) || [];
        setValues({
            ...values,
            name: queryParams.get('name')  || '',
            email: queryParams.get('email') || '',
            phoneNumber: phone[1] || '',
            phoneDDD: phone[0] || '',
        });
    },[location.search]);

    const getAddress = () => {
        if (values.zipcode && values.zipcode.replaceAll('\u2000', '').length >= 8) {
            getAddressByCep(values.zipcode).then(response => {
                const address = response.data;
                setShowAddress(true);
                if (address.erro) {
                    Toastify({
                        text: "O CEP é inválido",
                        duration: 3000,
                        newWindow: true,
                        close: false,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: false,
                        backgroundColor: 'var(--orange)'
                    }).showToast();
                    setValues({
                        ...values,
                        street: '',
                        neighborhood: '',
                        city: '',
                        state: '',
                        zipcode: '',
                    });
                } else {
                    setValues({
                        ...values,
                        street: address.logradouro,
                        neighborhood: address.bairro,
                        city: address.localidade,
                        state: address.uf
                    });
                }
            });
        }
    }

    const handleChange = (name: string, value: any, maxLength?: number) => {
        if (!maxLength || (value?.length <= maxLength)) {
            setValues({...values, [name]: value});
        }
    }

    function isStatus(status: 'invalid'|'valid', name: string): boolean {
       if (status === 'valid') {
           return touched.includes(name) && !getError(errors, name);
       } else if (status === 'invalid') {
           return (submitted || touched.includes(name)) && getError(errors, name);
       }
       return false;
    }

    function Address({values, handleChange, errors = []}: any) {
        return (
            <Grid container spacing={2}>
                <Grid item md={2} xs={3}>
                    <FormControl variant="outlined" size="small" fullWidth
                                 error={getError(errors, 'personal.state')}>
                        <InputLabel>Estado</InputLabel>
                        <Select
                            value={values.state}
                            onChange={(e) => handleChange('state', e.target.value)}
                        >
                            {STATES.map((state) => (
                                <MenuItem key={state.value} value={state.value}>{state.value}</MenuItem>
                            ))
                            }
                        </Select>
                        {getError(errors, 'personal.state') && (
                            <FormHelperText error>{getError(errors, 'personal.state')?.message}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item md={5} xs={9}>
                    <TextField
                        fullWidth
                        label="Cidade"
                        style={{marginBottom: 0}}
                        size="small"
                        value={values.city}
                        error={getError(errors, 'personal.city')}
                        helperText={getError(errors, 'personal.city')?.message}
                        onChange={e => handleChange('city', e.target.value)}
                        variant="outlined"
                    />
                </Grid>
                <Grid item md={5} xs={12}>
                    <TextField
                        fullWidth
                        label="Bairro"
                        style={{marginBottom: 0}}
                        size="small"
                        value={values.neighborhood}
                        error={getError(errors, 'personal.neighborhood')}
                        helperText={getError(errors, 'personal.neighborhood')?.message}
                        onChange={e => handleChange('neighborhood', e.target.value)}
                        variant="outlined"
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        fullWidth
                        label="Rua ou Avenida"
                        style={{marginBottom: 0}}
                        error={getError(errors, 'personal.street')}
                        helperText={getError(errors, 'personal.street')?.message}
                        size="small"
                        value={values.street}
                        onChange={e => handleChange('street', e.target.value)}
                        variant="outlined"
                    />
                </Grid>
                <Grid item md={3} xs={6}>
                    <TextField
                        fullWidth
                        label="Número"
                        type="number"
                        style={{marginBottom: 0}}
                        size="small"
                        className={`${isStatus('valid', 'personal.number') ? 'input-valid' : ''}`}
                        onKeyPressCapture={() => handleTouched('personal.number')}
                        onBlur={() => handleTouched('personal.number')}
                        error={isStatus('invalid', 'personal.number')}
                        helperText={isStatus('invalid', 'personal.number') && getError(errors, 'personal.number')?.message}
                        value={values.number}
                        onChange={e => handleChange('number', e.target.value)}
                        variant="outlined"/>
                </Grid>
                <Grid item md={3} xs={6}>
                    <TextField
                        fullWidth
                        label="Complemento"
                        style={{marginBottom: 0}}
                        size="small"
                        value={values.additional_details}
                        onChange={e => handleChange('additional_details', e.target.value)}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container justifyContent="space-between" spacing={2}>
            <Grid item md={12} xs={12}>
                <TextField
                    fullWidth
                    label="Nome completo"
                    placeholder="Seu nome completo"
                    style={{marginBottom: 15}}
                    size="small"
                    className={`${isStatus('valid', 'personal.name') ? 'input-valid' : ''}`}
                    onKeyPressCapture={() => handleTouched('personal.name')}
                    onBlur={() => handleTouched('personal.name')}
                    error={isStatus('invalid', 'personal.name')}
                    helperText={isStatus('invalid', 'personal.name') && getError(errors, 'personal.name')?.message}
                    value={values.name}
                    onChange={e => handleChange('name', e.target.value)}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><FaUser color="var(--dark2)"/></InputAdornment>,
                    }}/>
                <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    placeholder="Seu email"
                    style={{marginBottom: 15}}
                    className={`${isStatus('valid', 'personal.email') ? 'input-valid' : ''}`}
                    onKeyPressCapture={() => handleTouched('personal.email')}
                    onBlur={() => handleTouched('personal.email')}
                    error={isStatus('invalid', 'personal.email')}
                    helperText={isStatus('invalid', 'personal.email') && getError(errors, 'personal.email')?.message}
                    size="small"
                    value={values.email}
                    onChange={e => handleChange('email', e.target.value)}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><FaEnvelope color="var(--dark2)"/></InputAdornment>,
                    }}/>
                <Grid container spacing={2} style={{marginBottom: 7}}>
                    <Grid item md={4} xs={12}>
                        <TextField
                            fullWidth
                            label="CPF ou CNPJ"
                            placeholder="Seu CPF ou CNPJ"
                            style={{marginBottom: 15}}
                            className={`${isStatus('valid', 'personal.cpfCnpj') ? 'input-valid' : ''}`}
                            onKeyPressCapture={() => handleTouched('personal.cpfCnpj')}
                            onBlur={() => handleTouched('personal.cpfCnpj')}
                            error={isStatus('invalid', 'personal.cpfCnpj')}
                            helperText={isStatus('invalid', 'personal.cpfCnpj') && getError(errors, 'personal.cpfCnpj')?.message}
                            size="small"
                            value={values.cpfCnpj}
                            onChange={e => handleChange('cpfCnpj', e.target.value, 14)}
                            variant="outlined"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><FaLock color="var(--dark2)"/></InputAdornment>,
                            }}/>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <div style={{display: 'flex', gap: 10}}>
                            <TextField
                                label="DDD"
                                placeholder="DDD"
                                style={{marginBottom: 15, width: 150}}
                                className={`${isStatus('valid', 'personal.phoneDDD') ? 'input-valid' : ''}`}
                                onKeyPressCapture={() => handleTouched('personal.phoneDDD')}
                                onBlur={() => handleTouched('personal.phoneDDD')}
                                error={isStatus('invalid', 'personal.phoneDDD')}
                                helperText={isStatus('invalid', 'personal.phoneDDD') && getError(errors, 'personal.phoneDDD')?.message}
                                size="small"
                                value={values.phoneDDD}
                                onChange={e => handleChange('phoneDDD', e.target.value, 2)}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><FaPhone color="var(--dark2)"/></InputAdornment>,
                                }}/>
                            <TextField
                                fullWidth
                                label="Celular"
                                placeholder="Seu número de celular"
                                style={{marginBottom: 15}}
                                className={`${isStatus('valid', 'personal.phoneNumber') ? 'input-valid' : ''}`}
                                onKeyPressCapture={() => handleTouched('personal.phoneNumber')}
                                onBlur={() => handleTouched('personal.phoneNumber')}
                                error={isStatus('invalid', 'personal.phoneNumber')}
                                helperText={isStatus('invalid', 'personal.phoneNumber') && getError(errors, 'personal.phoneNumber')?.message}
                                size="small"
                                value={values.phoneNumber}
                                onChange={e => handleChange('phoneNumber', e.target.value, 9)}
                                variant="outlined"/>
                        </div>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            fullWidth
                            label="CEP"
                            placeholder="Seu CEP"
                            style={{marginBottom: 15}}
                            className={`${isStatus('valid', 'personal.zipcode') ? 'input-valid' : ''}`}
                            onKeyPressCapture={() => handleTouched('personal.zipcode')}
                            onBlur={() => handleTouched('personal.zipcode')}
                            error={isStatus('invalid', 'personal.zipcode')}
                            helperText={isStatus('invalid', 'personal.zipcode') && getError(errors, 'personal.zipcode')?.message}
                            size="small"
                            value={values.zipcode}
                            onChange={e => handleChange('zipcode', e.target.value, 8)}
                            variant="outlined"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><FaMapMarkerAlt color="var(--dark2)"/></InputAdornment>,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={getAddress} size="small" title="Buscar Endereço">
                                            <FaSearch color="var(--dark2)"/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}/>
                    </Grid>
                </Grid>
                {showAddress && Address({values, handleChange, errors})}
            </Grid>
        </Grid>
    )
}
