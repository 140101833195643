import  { useCallback, useEffect, useMemo, useState } from "react";
import {Button, useMediaQuery} from "@material-ui/core";
import styles from '../../../assets/styles/components/posCheckoutCreateAccount';
import stylesBase from '../../../assets/styles/components/base';
import stylesSteps from '../../../assets/styles/components/stepsCheckout';
import ControlSteps from "./ControlSteps";
import { api } from "../../../services/api";
import { API_URL } from "../../../pages/PosCheckout";
import { usePostCheckout } from "../../../hooks/PosCheckoutContext";
import { ButtonWithIcon } from "../buttonWithIcon";
import { CustomInput } from "../customInput";
import axios from "axios";

export default function CreateAboutCompany ({field, form, ...props}: any) {
    const classes = styles();
    const classesBase = stylesBase();
    const classesSteps = stylesSteps();
    const isMobile = useMediaQuery('(max-width: 960px)');
    const [loading, setLoading] = useState(false);
    const {handleError,handleStep} = usePostCheckout();

    const {currentStep,totalSteps, nextStep } = props;

    useEffect(()=> {
        handleStep(2);
    }, [])

    const memoValidateErrors = useMemo(() => {
        const {
            companyname,
            companyoperation,
            companyamountemployees
        } = form.values;
        if(!companyname || !companyoperation || !companyamountemployees) return true;
        return false;
    }, [form]);

    async function handleSubmit(){
        setLoading(true);
        try {
            const {values} = form;
            const {passwordConfirmation, whatsapp, userIdRef, companyamountemployees, keySubscription, ...body} = values;
            const whatsFormated = whatsapp.split("+").join("");
            const {data} = await api.post(`${API_URL}/vindi/${keySubscription}`, {...body, whatsapp: whatsFormated, companyamountemployees: Number(companyamountemployees) });
            await sendCodeWhats(data.dados);
            form.setFieldValue("userIdRef", data.dados);
            nextStep();
        }catch(err){
            if(axios.isAxiosError(err)){
                handleError(err.response?.data.mensagem);
            } else {
                handleError("Algo aconteceu, tente novamente mais tarde.");

            }
        } finally {
            setLoading(false);
        }
    }


    async function sendCodeWhats(userIdRef:string){
        try {
           await api.get(`${API_URL}/validate/email/${userIdRef}/whats`);
        }catch(err){
            console.error(err);
        } 
    }

    const CustomErroMessage = useCallback(({field}:any) => {
        return (form.errors[field] && form.touched[field]) ? <p className={classesBase.textSmall} style={{color:"#f1003b"}}>{form.errors[field]}</p> : null
    }, [field, form])


  return (
        <div className={classesSteps.wrapperStep}>
            <div className={classes.containerDialog}>
                <div className={classes.containerHeader}>
                <img src="https://app.ihelpchat.com/ihelp-angular/assets/images/logotipo_color.png" alt="IHelp" className={classes.logo}/>
                    <p className={classesBase.textTitle} >Conte mais sobre sua empresa</p>
                </div>
            </div>

                <div className={classes.containerForm} >
                    <p className={classesBase.label}>Qual nome da sua empresa ?</p>
                    <CustomInput
                            name="companyname"
                            placeholder="Ex: Empresa Joy"
                            className={classes.customInput}
                            onChange={(e:any)=> {
                                form.validateField("companyname")
                                form.setFieldTouched('companyname');
                                form.setFieldValue("companyname", e.target.value);
                            }}
                    />
                    <CustomErroMessage field="companyname"/>

                    <p className={classesBase.label}>Qual sua área de atuação ?</p>
                    <CustomInput
                            name="companyoperation"
                            placeholder="Ex: Escritório de Contabilidade"
                            className={classes.customInput}
                            onChange={(e:any)=> {
                                form.setFieldTouched('companyoperation');
                                form.setFieldValue("companyoperation", e.target.value);
                            }}
                    />
                    <CustomErroMessage field="companyoperation"/>

                    <p className={classesBase.label}>Quantas pessoas trabalham na sua empresa?</p>

                    <CustomInput
                            name="companyamountemployees"
                            placeholder="Ex: 500"
                            className={classes.customInput}
                            type="number"
                            onChange={(e:any)=> {
                                form.setFieldTouched('companyamountemployees');
                                form.setFieldValue("companyamountemployees", e.target.value);
                            }}
                    />
                    <CustomErroMessage field="companyamountemployees"/>

                    </div>
                    <ControlSteps totalSteps={totalSteps} currentStep={currentStep}/>

                    <div className={classesSteps.wrapperFooter}>
                        <Button
                        variant="outlined"
                        className={classesBase.buttonSecondary}
                        style={{width: isMobile ? '100%' : 'auto'}}
                        onClick={()=> {
                            handleStep(1);
                            props.previousStep();
                        }}>VOLTAR</Button>

                        <ButtonWithIcon 
                            loadingButton={loading}
                            style={{width: isMobile ? '100%' : 'auto'}}
                            disabled={loading || memoValidateErrors}
                            onClick={handleSubmit}
                        />
                    </div>
    </div>
  )
}
