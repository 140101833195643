import MaskedInput from "react-text-mask";
import React from "react";

export default function TextMaskCustom(props: any) {
    const { inputRef, ...other } = props;
    let mask = props.mask || '';
    if (props.name === 'phone') {
        mask = props.value.replace(/\D/g, '').length < 11
            ? ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]
            : ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
    if (props.name === 'cpfCnpj') {
        mask = props.value.replace(/\D/g, '').length <= 11
            ? [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/,]
            : [/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
    }
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            placeholderChar={'\u2000'}
            placeholder={props.placeholder || ''}
        />
    );
}
