import stylesBase from '../../assets/styles/components/base';
import {FaEye, FaEyeSlash} from 'react-icons/fa';
import { InputProps } from '@material-ui/core';

export function CustomInput (props: any) {
    const classesBase = stylesBase();

    const style = {
      wrapper: {
        position:"relative",
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between"
      },

      icon: {
        position:"absolute",
        right:35,
        cursor: "pointer"
      }

    }

  return (
    <div style={style.wrapper as any}>
      <input {...props} max={props.max} maxLength={props.maxLength} style={props.style} className={classesBase.customInput}/>
      {props.hasIconEye && props.type === "password" && <FaEye style={style.icon as any} color="#6D6D6D" onClick={props.changeType}/>}
      {props.hasIconEye &&  props.type === "text" && <FaEyeSlash style={style.icon as any} color="#6D6D6D"  onClick={props.changeType}/>}
      
    </div>
  )
}
