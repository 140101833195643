import {makeStyles, Theme} from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
    container: {
        paddingTop: 40,
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    title: {
        fontFamily: 'poppins, sans-serif',
        color: '#ffffff',
        fontSize: 16,
        fontWeight: 600,
        margin: '20px 0',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    title2: {
        fontFamily: 'poppins, sans-serif',
        color: '#ffffff',
        fontSize: 14,
        fontWeight: 600,
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    text: {
        fontFamily: 'poppins, sans-serif',
        color: '#ffffff',
        fontSize: 13,
        fontWeight: 500,
        opacity: 0.65,
        margin: '5px 0',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    copyright: {
        backgroundColor: '#000',
        padding: '20px 0',
        marginTop: 30,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    colSmCenter: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    help: {
        display: 'flex',
        flexDirection: 'column',
        '& > p': {
            margin: '0 0 8px',
            fontWeight: 500,
            fontSize: '.8rem',
            color: 'rgba(0, 0, 0, .65)',
            '& > a': {
                color: '#317FAB'
            }
        }
    },
    shieldPayment: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > div': {
            textTransform: 'uppercase',
            marginLeft: 10,
            '& > p': {
                margin: 0,
                color: 'var(--ihelp-primary)',
                fontWeight: 900,
                fontSize: '1rem'
            },
            '& > span': {
                margin: 0,
                color: 'var(--dark)',
                opacity: .65,
                fontWeight: 900,
                fontSize: '.7rem',
                '&:last-child': {
                    display: 'block',
                    marginTop: 0,
                }
            }
        }
    }
}))
