import {makeStyles, Theme} from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        textTransform: 'uppercase',
        fontWeight: 600,
        alignItems: 'flex-start',
        marginTop: 20,
        marginBottom: 15,
        '&:first-child': {
            marginTop: 0
        },
        '&:last-child': {
            marginBottom: 0
        },
        '&::after': {
            content: '" "',
            width: '100%',
            height: 2,
            backgroundColor: 'var(--gray)',
            position: 'relative',
            transform: 'translateY(-13px)',
            zIndex: 1
        }
    },
    title: {
        fontFamily: 'Lato, sans-serif',
        fontWeight: 900,
        fontSize: '1.1rem',
        color: 'var(--dark)',
        backgroundColor: '#ffffff',
        margin: 0,
        zIndex: 2,
        paddingRight: 10,
        textTransform: 'uppercase'
    },
    step: {
        color: 'var(--gray)',
        fontSize: '0.9rem',
        margin: 0,
        textTransform: 'uppercase'
    },
}));
