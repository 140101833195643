import React, { useEffect } from "react"
import {Button, Container, Grid, Snackbar} from "@material-ui/core";
import styles from '../assets/styles/components/posCheckout';
import stylesBase from '../assets/styles/components/base';
import {IoIosArrowForward} from "react-icons/io";
import {BsWhatsapp} from "react-icons/bs";
import { APPLICATION_CONFIG } from "../config/application";
import { useState } from "react";

// const URL = "https://checkout.ihelpchat.com/?checkout";
const URL = "https://checkout.ihelpchat.com/checkout/?checkout";
// const URL = "http://localhost:3000/?checkout";
export const API_URL = `${APPLICATION_CONFIG.environments["checkout"].apiUrl2}`;

export default function PosCheckout() {
    const classes = styles();
    const classesBase = stylesBase();
    const [openToast,setOpenToast] = useState(false);

    
    function handleCopy(){
        const subId = window.localStorage.getItem("@ihelp_KeyValue");
        setOpenToast(true);
        navigator.clipboard.writeText(`${URL}&sub=${subId}`);
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        let subId = params.get('sub');

        if(subId){
            navigator.clipboard.writeText(`${URL}&sub=${subId}`);
            window.localStorage.setItem("@ihelp_KeyValue", subId);
        }
    }, [])
    
    return (
        <>
            <Container  style={{maxWidth:"1500px"}}>
                <div className={classes.container}> 

                <Grid container>
                    <img src="https://app.ihelpchat.com/ihelp-angular/assets/images/logotipo_color.png" alt="IHelp" style={{width: '165px', borderRadius: 6, margin: '10px auto', display: 'block'}}/>
                    <div className={classes.headerIcon}>
                        <a href="https://api.whatsapp.com/send?phone=551730422307&text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20com%20o%20%23onboarding" target="_blank" rel="noreferrer" >
                            <BsWhatsapp size={32} color="#25D366" />
                            <p className={classesBase.textSmall} style={{marginTop:0}}>dúvidas?</p>
                        </a>
                    </div>
                </Grid>

                <Grid container justifyContent="center" spacing={2}>
                    <h4 className={classesBase.textTitle}>Parabéns! Sua assinatura do Ihelp foi realizada com sucesso</h4>
                </Grid>


                <Grid container justifyContent="center"  spacing={2} style={{flexDirection:"column"}}>
                   <p className={classesBase.textMedium} style={{fontWeight:300}}>Estamos verificando as informações sobre seu pagamento, você receberá um e-mail com <br /> as atualizações da sua compra.</p>

                   <p className={classesBase.textMedium} style={{fontWeight:300}}>Enquanto isso você já pode utilizar o ihelp para revolucionar seu atendimento.</p>
                </Grid>

                <Grid container justifyContent="center" alignItems="center" direction="column" spacing={1}>
                    <Button variant="outlined" className={classesBase.buttonPrimary} onClick={() => window.location.replace("?checkout_success")} style={{padding:0, width:180, height:48, fontSize:18}}>
                        INICIAR
                        <IoIosArrowForward  size={20}/>
                    </Button>
                    <p className={classesBase.textDefault} style={{textAlign:"center", cursor:"pointer"}} onClick={handleCopy}>Não é você que irá configurar a plataforma? <br></br> Compartilhe esse <strong>link</strong> com o responsável</p>
                </Grid>
                </div>

                <Snackbar
                    open={openToast}
                    autoHideDuration={1000}
                    onClose={() => setOpenToast(false)}
                    message="Link copiado com sucesso"
                />

            </Container>
        </>
    )
}
