import React, {useState, useEffect} from 'react';
import styles from '../assets/styles/components/plan';
import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    TextField, createTheme, MuiThemeProvider, useMediaQuery
} from "@material-ui/core";
import {FaPlus, FaMinus} from 'react-icons/fa';
import {getError} from "../helpers/validate.helpers";

interface PlanProps {
    errors: any,
    valueChange: (value: any) => any,
    touched: string[],
    handleTouched: (value: any) => any,
}
export default function PlanSection(props: PlanProps) {
    const {errors, valueChange, touched, handleTouched} = props;
    const classes = styles();
    const isMobile = useMediaQuery('max-width: 500px');
    const [user, setUser] = useState(3);
    const [whatsAppNumber, setWhatsAppNumber] = useState(1);
    const [period, setPeriod] = useState('monthly');

    useEffect(() => {
        valueChange({user, whatsAppNumber, period});
    }, [user, whatsAppNumber, period]);

    const theme = createTheme({
        palette: {
            primary: {
                main: '#6fa1e3',
            },
            secondary: {
                main: '#ffffff'
            }
        }
    });

    return (
        <Grid container justifyContent="space-between" spacing={2}>
            <Grid item md={12} xs={12}>
                <FormControl component="fieldset" fullWidth>
                    <MuiThemeProvider theme={theme}>
                        <RadioGroup aria-label="gender" name="gender1" value={period} onChange={e => {
                            setPeriod(e.target.value)
                        }}>
                            {[
                                {title: 'Plano Mensal', value: 'monthly', subtitle: ''},
                                // {title: 'Plano Semestral', value: 'semester', subtitle: 'Contrate e ganhe 50% de desconto na implementação'},
                                // {title: 'Plano Anual', value: 'yearly', subtitle: 'Contrate e a implementação e treinamento sai por nossa conta'},
                            ].map(plan => (
                                <FormControlLabel
                                    key={`plan-${plan.value}`}
                                    className={classes.radio}
                                    value={plan.value}
                                    control={<Radio color="primary" />}
                                    label={(
                                        <p>
                                            {plan.title}<br/>{
                                            plan.subtitle && <small>{plan.subtitle}</small>}
                                        </p>
                                    )} />
                            ))}

                        </RadioGroup>
                    </MuiThemeProvider>
                </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
                <Grid container spacing={2} style={{gap: 10, marginTop: 5, flex: 1, alignContent: 'space-between'}}>
                    <div style={{flex: 1}}>
                    {Counter('Quantidade de Usuários', user, setUser, errors, classes, {min: 3, errorKey: 'plan.user'})}
                    <p className={classes.labelUserCount}>Mínimo de 3 usuários</p>
                    </div>
                    {Counter('Números de WhatsApp', whatsAppNumber, setWhatsAppNumber, errors, classes, {min: 1, errorKey: 'plan.whatsAppNumber'})}
                </Grid>
            </Grid>
        </Grid>
    )
}

interface CounterConfig {
    min: number;
    errorKey: string;
}

function Counter(label: string, value: any, handleChange: any, errors: any, classes: any, config: CounterConfig) {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    return (
        <div className={classes.counterContainer} style={!isMobile ? {flex: 1} : {}}>
            <IconButton size="small" style={{margin: '5px 5px 0 0', border: '1px solid'}}
                        onClick={() => {handleChange(value <= config.min ? config.min : parseInt(value) - 1)}}>
                <FaMinus />
            </IconButton>
            <TextField
                label={<span style={{display: 'block', fontSize: 17, minWidth: '200px'}}>{label}</span>}
                inputProps={{
                    min: config.min,
                    style: {
                        textAlign: 'center'
                    }
                }}
                type="number"
                placeholder={label}
                style={{
                    marginBottom: 15,
                    flex: 1,
                }}
                size="small"
                value={value}
                onChange={e => handleChange(e.target.value)}
                variant="outlined"
                error={getError(errors, config.errorKey)}
                helperText={getError(errors, config.errorKey)?.message}
            />
            <IconButton size="small" style={{margin: '5px 0 0 5px', border: '1px solid'}} onClick={() => {handleChange(parseInt(value) + 1)}}>
                <FaPlus />
            </IconButton>
        </div>
    )
}
