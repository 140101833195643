import styles from '../assets/styles/components/footer';
import shieldPayment from '../assets/images/shieldPayment.svg';

export default function Footer() {
    const classes = styles();

    return (
        <div className={classes.container}>
            <div className={classes.help}>
                {/*<p>Precisa de ajuda? <a href="/">Contato do vendedor</a></p>*/}
                {/*<p>Precisa de ajuda no preenchimento desta tela? <a href="/">Mande um e-mail para nosso suporte</a></p>*/}
                <p>
                    Ao prosseguir você está concordando com os
                    <a href="https://site.ihelpchat.com/termos-de-uso" style={{ margin: '0 5px' }}>
                        Termos de compra e Políticas
                    </a>
                </p>
                <p>IHelp © 2021 - Todos os direitos reservados.</p>
            </div>
            <div className={classes.shieldPayment}>
                <img src={shieldPayment} alt="Compra segura" />
                <div>
                    <span>Compra segura</span>
                    <p>Site Protegido</p>
                    <span>Certificado SSL</span>
                </div>
            </div>
        </div>
    )
}
