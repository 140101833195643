import  { useEffect, useMemo, useState } from "react";
import styles from '../../../assets/styles/components/posCheckoutCreateAccount';
import stylesBase from '../../../assets/styles/components/base';
import IconDashboard from '../../../assets/images/checkout/icon-dashboard.png';
import { api } from "../../../services/api";
import { API_URL } from "../../../pages/PosCheckout";
import { usePostCheckout } from "../../../hooks/PosCheckoutContext";
import { ButtonWithIcon } from "../buttonWithIcon";
import { CustomInput } from "../customInput";
import axios from "axios";
import { useMediaQuery } from "@material-ui/core";

export default function CreateEmail ({field, form, ...props}: any) {
    const classes = styles();
    const classesBase = stylesBase();
    const [loading, setLoading] = useState(false);
    const {handleError, handleStep} = usePostCheckout();
    const isMobile = useMediaQuery('(max-width: 960px)');


    useEffect(()=>{
        handleStep(0);
    }, [])

    const memoValidateErrors = useMemo(() => {
        const hasErrors = form.errors["useremail"];
        if(hasErrors) return true;
        return false;
    }, [form]);


    async function handleCheckEmailAndNextStep(){
        if(!field.value) return;

        setLoading(true);
        
        try {
           await api.post(`${API_URL}/configurations/users/check-email`, {
                Email: field.value
            });
        props.nextStep();
        }catch(err){
            if(axios.isAxiosError(err)){
                handleError(err.response?.data.mensagem);
            }
            else {
                handleError("Email inválido, tente novamente com outro e-mail.");
            }
        }
        finally {
            setLoading(false);
        }
    }

  return (
        <>
            <div className={classes.containerDialog}>
                <div className={classes.containerHeader}>
                    <img src="https://app.ihelpchat.com/ihelp-angular/assets/images/logotipo_color.png" alt="IHelp" className={classes.logo}/>
                    <p className={classesBase.textLarge} >Vamos criar sua conta</p>
                </div>
                <img src={IconDashboard} alt="icone-image" className={classes.firstImage} />
            </div>

            <div className={classes.containerFooter} style={{marginBottom:50}} >
                <div className={classes.containerForm}>
                    <p className={classesBase.label}>Seu e-mail profissional</p>
                    <CustomInput
                    {...field}
                    placeholder="seunome@empresa.com"
                    required
                    style={{marginRight:isMobile && 0}}
                    onChange={field.onChange}
                    />
                </div>
                
                <ButtonWithIcon 
                    loadingButton={loading}
                    style={{width: isMobile ? `100%` : 'auto', marginTop: isMobile ? 10 : 0}}
                    disabled={loading || memoValidateErrors}
                    onClick={handleCheckEmailAndNextStep}
                />
 
            </div>
        <p className={classesBase.textMedium} style={{fontWeight:300, textAlign: "center", color:"#6D6D6D", fontSize:14}}> Ao criar sua conta, você concorda com nossa <a style={{ color:"#6D6D6D"}} href="https://ihelpchat.com/termos-de-uso" target="_blank" rel="noreferrer"><strong>Política de Privacidade</strong> e <strong>Termos e Condições.</strong></a></p>

 
    </>
  )
}
