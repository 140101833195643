import {isValidCnpj, isValidCpf} from "./validateCpf.helpers";
import moment from 'moment';

interface Values {
    plan: {
        user: number;
        whatsAppNumber: number;
        period: string;
    },
    personal: {
        name: string;
        email: string;
        cpfCnpj: string;
        phone: string
    },
    payment: {
        paymentMethod: 'credit_card'|'billet';
        creditCardNumber: string;
        creditCardName: string;
        creditCardExpiry: string;
        creditCardCvc: string;
        inputFocused: string;
        zipcode: string;
        street: string;
        neighborhood: string;
        city: string;
        state: string;
        additional_details: string;
        number: string;
    }
}

export function getError(errors: any, name: string) {
    return errors.find((err: any) => err.field === name);
}

export const formatJustNumber = (value: string = '') => value.replace(/\D/g, '');

export function validate(values: any|Values): any {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const numberRegex = /[0-9]$/;
    const letterRegex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ ']+$/;
    const errors = [];
    if (!values.plan?.user || values.plan?.user < 3) {
        errors.push({field: 'plan.user', message: 'O número mínimo de usuários é 3'});
    }
    if (!values.plan?.whatsAppNumber || values.plan?.whatsAppNumber < 1) {
        errors.push({field: 'plan.whatsAppNumber', message: 'O número mínimo de números é 1'});
    }
    if (!values.personal?.name || values.personal?.name.trim().length < 1) {
        errors.push({field: 'personal.name', message: 'O nome é obrigatório'});
    }
    if (values.personal?.name && (values.personal?.name.trim().split(' ').length < 2)) {
        errors.push({field: 'personal.name', message: 'Informe um nome válido'});
    }
    if (!letterRegex.test(values.personal?.name)) {
        errors.push({field: 'personal.name', message: 'Somente letras são permitidos'});
    }
    if (!values.personal?.email) {
        errors.push({field: 'personal.email', message: 'O nome é obrigatório'});
    }
    if (!emailRegex.test(values.personal.email)) {
        errors.push({field: 'personal.email', message: 'Informe um e-mail válido'});
    }
    if (!values.personal?.cpfCnpj) {
        errors.push({field: 'personal.cpfCnpj', message: 'O CPF/CNPJ é obrigatório'});
    }
    if (!numberRegex.test(values.personal?.cpfCnpj)) {
        errors.push({field: 'personal.cpfCnpj', message: 'Somente números são permitidos'});
    }
    if (formatJustNumber(values.personal?.cpfCnpj)?.length < 11 || (formatJustNumber(values.personal?.cpfCnpj)?.length === 11 && !isValidCpf(values.personal?.cpfCnpj))) {
        errors.push({field: 'personal.cpfCnpj', message: 'Informe um CPF válido'});
    }
    if (formatJustNumber(values.personal?.cpfCnpj)?.length > 11 && !isValidCnpj(values.personal?.cpfCnpj)) {
        errors.push({field: 'personal.cpfCnpj', message: 'Informe um CNPJ válido'});
    }
    if (!values.personal?.phoneNumber) {
        errors.push({field: 'personal.phoneNumber', message: 'O celular é obrigatório'});
    }
    if (!numberRegex.test(values.personal?.phoneNumber)) {
        errors.push({field: 'personal.phoneNumber', message: 'Somente números são permitidos'});
    }
    if (formatJustNumber(values.personal?.phoneNumber)?.length !== 9) {
        errors.push({field: 'personal.phoneNumber', message: 'Informe um celular válido'});
    }
    if (!values.personal?.phoneDDD) {
        errors.push({field: 'personal.phoneDDD', message: 'O DDD é obrigatório'});
    }
    if (!numberRegex.test(values.personal?.phoneDDD)) {
        errors.push({field: 'personal.phoneDDD', message: 'Somente números são permitidos'});
    }
    if (formatJustNumber(values.personal?.phoneDDD)?.length !== 2) {
        errors.push({field: 'personal.phoneDDD', message: 'Informe um DDD válido'});
    }
    if (!values.personal?.zipcode) {
        errors.push({field: 'personal.zipcode', message: 'O CEP é obrigatório'});
    }
    if (!numberRegex.test(values.personal?.zipcode)) {
        errors.push({field: 'personal.zipcode', message: 'Somente números são permitidos'});
    }
    if (formatJustNumber(values.personal?.zipcode)?.length !== 8) {
        errors.push({field: 'personal.zipcode', message: 'Informe um CEP válido'});
    }
    if (!values.personal?.street) {
        errors.push({field: 'personal.street', message: 'O endereço é obrigatório'});
    }
    if (!values.personal?.neighborhood) {
        errors.push({field: 'personal.neighborhood', message: 'O bairro é obrigatório'});
    }
    if (!values.personal?.city) {
        errors.push({field: 'personal.city', message: 'A cidade é obrigatória'});
    }
    if (!values.personal?.state) {
        errors.push({field: 'personal.state', message: 'O estado é obrigatório'});
    }
    if (!values.personal?.number) {
        errors.push({field: 'personal.number', message: 'O número é obrigatório'});
    }
    if (values.payment.paymentMethod === 'credit_card') {
        if (!values.payment?.creditCardNumber) {
            errors.push({field: 'payment.creditCardNumber', message: 'O número do cartão é obrigatório'});
        }
        if (formatJustNumber(values.payment?.creditCardNumber)?.length !== 16) {
            errors.push({field: 'payment.creditCardNumber', message: 'Informe um número válido'});
        }
        if (!values.payment?.creditCardName) {
            errors.push({field: 'payment.creditCardName', message: 'O nome do titular é obrigatório'});
        }
        if (!values.payment?.creditCardCvc) {
            errors.push({field: 'payment.creditCardCvc', message: 'O código de segurança é obrigatório'});
        }
        if (formatJustNumber(values.payment?.creditCardCvc)?.length !== 3) {
            errors.push({field: 'payment.creditCardCvc', message: 'Informe um código válido'});
        }
        if (!values.payment?.creditCardExpiry) {
            errors.push({field: 'payment.creditCardExpiry', message: 'A validade é obrigatória'});
        }
        if (formatJustNumber(values.payment?.creditCardExpiry)?.length !== 6) {
            errors.push({field: 'payment.creditCardExpiry', message: 'Informe uma data válida'});
        }
        if (formatJustNumber(values.payment?.creditCardExpiry)?.length === 6
            && !moment(values.payment?.creditCardExpiry, 'MM/YYYY').isValid()) {
            errors.push({field: 'payment.creditCardExpiry', message: 'Data inválida'});
        }
        if (formatJustNumber(values.payment?.creditCardExpiry)?.length === 6
            && moment(values.payment?.creditCardExpiry, 'MM/YYYY').isBefore(moment())) {
            errors.push({field: 'payment.creditCardExpiry', message: 'Cartão expirado'});
        }
    }
    return errors;
}
