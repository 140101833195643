import React from "react"
import {Container} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import Background from '../assets/images/checkout/background.webp';
import CreateEmail from "../components/checkout/steps/CreateEmail";
import styles from '../assets/styles/components/posCheckoutCreateAccount';
import { Formik, Form, Field } from 'formik';
import CreateAboutYou from "../components/checkout/steps/CreateAboutYou";
import StepWizard from "react-step-wizard";
import CreateAboutCompany from "../components/checkout/steps/CreateAboutCompany";
import CreateValidatePhone from "../components/checkout/steps/CreateValidatePhone";
import * as yup from "yup";
import {  usePostCheckout } from "../hooks/PosCheckoutContext";

export default function PosCheckoutCreateAccount() {
    const breakPoint = 'lg';
    const classes = styles();
    const {currentStep} = usePostCheckout();

   const width = window.innerWidth;


    const initialValues = {
        "username": "",
        "useremail": "",
        "password": "",
        "passwordConfirmation":"",
        "whatsapp": "",
        "companyname": "",
        "companyoperation": "",
        "companyamountemployees": "",
        "userIdRef": "",
        "keySubscription":window.localStorage.getItem("@ihelp_KeyValue")
    }

      let validationSchema = yup.object().shape({
        useremail: yup.string().email().required("Email não válido."),
      });


      let validationSchemaTwo = yup.object().shape({
        username: yup.string().required("Preencha seu nome completo."),
        password: yup.string().min(8, "A senha deve ter no minimo 8 caracteres.").required("A senha não pode estar vazia."),
        passwordConfirmation: yup.string()
        .oneOf([yup.ref('password'), null], 'Senhas não conferem.'),
        whatsapp: yup.string()
      });

      let validationSchemaThree = yup.object().shape({
        companyname: yup.string().required("Nome da empresa é obrigatório"),
        companyoperation: yup.string().required("Área de atuação é obrigatório."),
        companyamountemployees: yup.number().required("Quantidade de funcionários é obrigatório."),
      });

      let  schemaArray = [validationSchema, validationSchemaTwo, validationSchemaThree];

      const currentValidationSchema = schemaArray[currentStep];


    return (
        <>
            <Container maxWidth={breakPoint}>
                <div className={classes.container} style={{backgroundImage:Background, backgroundRepeat:'no-repeat'}}> 
                      <Dialog
                        open={true}
                        onClose={() => {}}
                        BackdropProps={{ style: { backgroundImage:`url(${Background})`, backgroundSize: width <= 768 ? 'cover' : 'contain' } }}
                        maxWidth={"md"}
>
                        <div className={classes.wrapper}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={currentValidationSchema}
                                validateOnMount
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                }}>
                                {() => (
                                            <Form autoComplete="off">
                                                <StepWizard isLazyMount>
                                                    <Field component={CreateEmail} name="useremail"/>
                                                    <Field component={CreateAboutYou} />
                                                    <Field component={CreateAboutCompany} />
                                                    <Field component={CreateValidatePhone} />
                                                </StepWizard>
                                            </Form>
                                    )}
                            </Formik>
                        </div>
                    </Dialog>
                </div>

   

            </Container>
        </>
    )
}




