import {makeStyles, Theme} from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
    backdrop: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    container: {
        backgroundColor: '#fff',
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontWeight: 600,
        padding: 20,
        marginBottom: 10,
        transition: 'ease-in-out .3s',
        overflow: 'hidden',
        animation: `$scaleIn .3s ${theme.transitions.easing.easeIn}`
    },
    title: {
        margin: '0 0 15px',
        textAlign: 'center'
    },
    '@keyframes scaleIn': {
        '0%': {
            transform: 'scale(0)'
        },
        '100%': {
            transform: 'scale(1)'
        }
    }
}));
