import { Snackbar } from "@material-ui/core";
import React, { createContext, useContext, useState } from "react";
import { Alert } from "@material-ui/lab";

const PostCheckoutContext = createContext({} as any);

type PostCheckoutProviderProps = {
    children: React.ReactNode
}


export const PostCheckoutProvider = ({children}:PostCheckoutProviderProps) => {
    const [error, setError] = useState("");
    const [currentStep, setCurrentStep] = useState(0);

    const handleStep = (step:any) => {
        setCurrentStep(step);
    }

    const handleError = (error: string) => {
        setError(error);
    }

    const valueProvider = {
        error,
        currentStep,
        handleError,
        handleStep,
    }

    return (
        <PostCheckoutContext.Provider value={valueProvider}>
            {children}
            <Snackbar
                    open={!!error}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
            
                    autoHideDuration={1000}
                    onClose={() => setError("")}
                    message={error}
                >
                <Alert onClose={() => setError("")} severity="error" >
                    {error}
                </Alert>
                </Snackbar>
        </PostCheckoutContext.Provider>
    )
}

export const usePostCheckout = ()=>{
    const context = useContext(PostCheckoutContext);

    return context;
}