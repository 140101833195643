export const prices: any = {
    user: 65,
    whatsAppNumber: 100,
    implement: {
        monthly: {
            amount: 497,
        },
        semester: {
            amount: 497 / 2,
            discount: 497 / 2
        },
        yearly: {
            amount: 0,
            discount: 497
        },
    }
};

export function getNumberOfMonths(period: string): number {
    return period === 'yearly' ? 12 : period === 'semester' ? 6 : 1;
}
