import React from "react";
import styles from '../assets/styles/components/sectionTitle';

export default function SectionTitle(props: any) {
    const {
        step = '',
        title = ''
    } = props;
    const classes = styles();

    return (
        <div className={classes.container}>
            <span className={classes.step}>{step}</span>
            <h2 className={classes.title}>{title}</h2>
        </div>
    )
}
