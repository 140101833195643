import React, {useState, useEffect} from 'react';
import {Button, Card, CircularProgress, Container, Grid, useMediaQuery} from "@material-ui/core";
import SectionTitle from "../components/sectionTitle";
import PlanSection from "../sections/Plan";
import PersonalSection from "../sections/Personal";
import PaymentSection from "../sections/Payment";
import CardResume, {getTotal} from "../components/cardResume";
import Footer from "../components/footer";
import {formatJustNumber, validate} from "../helpers/validate.helpers";
import {checkout} from "../services/checkout.service";
import {getNumberOfMonths, prices} from '../helpers/resume.helpers';
import Toastify from 'toastify-js';
import Loading from "../components/loading";

export default function Checkout() {
    const breakPoint = 'lg';
    const isMobile = useMediaQuery('(max-width:600px)');
    const [errors, setErrors] = useState([]);
    const [touched, setTouched] = useState(['']);
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [values, setValues] = useState({
        plan: {
            user: 3,
            whatsAppNumber: 1,
            period: 'monthly'
        },
        personal: {
            name: '',
            email: '',
            cpfCnpj: '',
            phoneDDD: '',
            phoneNumber: '',
            zipcode: '',
            street: '',
            neighborhood: '',
            city: '',
            state: '',
            additional_details: '',
            number: ''
        },
        payment: {
            paymentMethod: 'credit_card',
            creditCardNumber: '',
            creditCardName: '',
            creditCardExpiry: '',
            creditCardCvc: '',
            inputFocused: '',
            installments: 1
        }
    });

    useEffect(() => {
        setErrors(validate(values));
    },[values]);

    const planSelect = (): boolean => {
        return values.plan.user > 0
            && values.plan.whatsAppNumber > 0
            && values.plan.period !== '';
    }

    function handleTouched(name: string) {
        !touched.includes(name) && setTouched([...touched, name]);
    }

    async function handleChange(section: 'plan'|'personal'|'payment', value: any) {
        await setValues({...values, [section]: value});
    }

    function handleSubmit() {
        const errors = validate(values);
        setErrors(errors);
        setSubmitted(true);
        if (errors.length === 0) {
            let data: any = {
                cpf_cnpj: formatJustNumber(values.personal.cpfCnpj),
                name: values.personal.name,
                email: values.personal.email,
                phone: `55${formatJustNumber(values.personal.phoneDDD)}${formatJustNumber(values.personal.phoneNumber)}`,
                period: values.plan.period,
                whatsapp_number: values.plan.whatsAppNumber,
                user: values.plan.user,
                payment_method_code: values.payment.paymentMethod,
                installments: values.plan.period === 'monthly' ? 1 : values.payment.installments,
                address: {
                    zipcode: values.personal.zipcode,
                    street: values.personal.street,
                    number: values.personal.number,
                    neighborhood: values.personal.neighborhood,
                    city: values.personal.city,
                    state: values.personal.state,
                    additional_details: values.personal.additional_details,
                    country: 'BR'
                }
            };
            if (values.payment.paymentMethod === 'credit_card') {
                data = {
                    ...data,
                    card_number: formatJustNumber(values.payment.creditCardNumber),
                    card_expiration: values.payment.creditCardExpiry,
                    card_cvv: values.payment.creditCardCvc,
                    holder_name: values.payment.creditCardName,
                }
            }
            setSubmitting(true);
            checkout(data).then((data) => {
                const subscriptionId = data.data.subscription;
                window.localStorage.setItem("@ihelp_KeyValue", subscriptionId);
                window.open("?checkout", '_self');
            }).catch(() => {
                setSubmitting(false);
                Toastify({
                    text: "Houve um problema com o pagamento. Verifique as informações e tente novamente.",
                    duration: 10000,
                    newWindow: true,
                    close: false,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    backgroundColor: 'var(--orange)'
                }).showToast();
            });
        } else {
            Toastify({
                text: "Por favor, preencha todos os dados corretamente",
                duration: 5000,
                newWindow: true,
                close: false,
                gravity: "top",
                position: "right",
                stopOnFocus: false,
                backgroundColor: 'var(--orange)'
            }).showToast();
        }
    }

    return (
        <>
            {submitting && <Loading />}
            <Container maxWidth={breakPoint}>
                <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item md={12} xs={12}>
                        <img src="https://app.ihelpchat.com/ihelp-angular/assets/images/logotipo_color.png" alt="IHelp 2" style={{width: '165px', borderRadius: 6, margin: '10px auto', display: 'block'}}/>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Card style={{padding: 20}}>
                            <SectionTitle step="01" title="Escolha seu plano" />
                            <PlanSection
                                errors={errors}
                                touched={touched}
                                handleTouched={handleTouched}
                                valueChange={(value: any) => handleChange('plan', value)} />
                            {isMobile && (
                                <CardResume
                                    reduced
                                    submitting={submitting}
                                    plan={values.plan}
                                    handleSubmit={handleSubmit}/>
                            )}
                            <SectionTitle step="02" title="Informações pessoais" />
                            {planSelect() && (
                                <PersonalSection errors={errors}
                                                 touched={touched}
                                                 handleTouched={handleTouched}
                                                 valueChange={(value: any) => handleChange('personal', value)}
                                                 submitted={submitted}
                                />
                            )}
                            <SectionTitle step="03" title="Forma de pagamento" />
                            {planSelect() && (
                                <PaymentSection errors={errors}
                                                planPeriod={values.plan.period}
                                                touched={touched}
                                                handleTouched={handleTouched}
                                                amount={getTotal({
                                                    prices,
                                                    plan: values.plan,
                                                    numberOfMonths: getNumberOfMonths(values.plan.period),
                                                    withImplement: true,
                                                    byMonth: false
                                                })}
                                                valueChange={(value: any) => handleChange('payment', value)}
                                                submitted={submitted}
                                />
                            )}
                            {!isMobile && (
                                <CardResume reduced submitting={submitting} plan={values.plan} handleSubmit={handleSubmit}/>
                            )}
                            {planSelect() && (
                                submitting ? (
                                    <div style={{display: 'flex', alignItems: 'center', marginTop: 20}}>
                                        <CircularProgress size={25}/>
                                        <p style={{color: 'var(--primary)', margin: '0 0 0 10px', textAlign: 'center'}}>
                                            Realizando a assinatura
                                        </p>
                                    </div>

                                ) : (
                                    <Button variant="contained"
                                            fullWidth color="primary"
                                            onClick={handleSubmit}
                                            style={{backgroundColor: 'var(--green)',color: '#fff', marginTop: 25}}>
                                        Assinar agora
                                    </Button>
                                )
                            )
                            }
                            <Footer />
                        </Card>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {planSelect() && !isMobile && (
                            <CardResume
                                alwaysOnTop
                                submitting={submitting}
                                plan={values.plan}
                                handleSubmit={handleSubmit}/>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
