import {makeStyles, Theme} from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
    container: {
        backgroundColor: '#fff',
        width: '100%',
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        marginBottom: 10,
        transition: 'ease-in-out .3s',
        overflow: 'hidden',
        animation: `$scaleIn .5s ${theme.transitions.easing.easeIn}`
    },
    title: {
        margin: '0 0 15px',
        textAlign: 'center'
    },
    line: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginBottom: 5
    },
    itemTitle: {
        fontSize: '0.9rem',
        fontWeight: 600,
        color: 'var(--dark)',
        '& small': {
            fontSize: '.8rem',
            opacity: .65
        }
    },
    itemPrice: {
        fontSize: '0.9rem',
        fontWeight: 600,
        color: 'var(--dark)',
        textAlign: 'right',
        '& small': {
            fontSize: '.8rem',
            opacity: .65
        }
    },
    reducedDesktopContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& p': {
            margin: '15px 0 0',
            fontWeight: 600,
            fontFamily: 'Lato, sans-serif',
            color: 'var(--dark)',
            textAlign: 'center',
        },
        '& h3': {
            margin: 0,
            fontWeight: 800,
            fontFamily: 'Lato, sans-serif',
            fontSize: '1.5rem',
            color: 'var(--dark)',
            textAlign: 'center'
        },
    },
    reducedContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& p': {
            margin: '25px 0 0',
            fontWeight: 800,
            fontFamily: 'Lato, sans-serif',
            color: 'var(--dark)',
            textAlign: 'center',
            fontSize: '1.1rem'
        },
        '& h3': {
            margin: '10px 0 0',
            fontWeight: 800,
            fontFamily: 'Lato, sans-serif',
            fontSize: '2rem',
            color: 'var(--dark)',
            textAlign: 'center'
        },
    },
    implementMessage: {
        margin: '5px 0 15px',
        fontWeight: 600,
        fontFamily: 'Lato, sans-serif',
        fontSize: '.75rem',
        color: 'var(--dark)',
        textAlign: 'center'
    },
    '@keyframes scaleIn': {
        '0%': {
            maxHeight: 0
        },
        '100%': {
            maxHeight: 1000
        }
    }
}));
