import { Button} from "@material-ui/core";
import stylesBase from '../../assets/styles/components/base';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading'
import { CSSProperties } from "react";

type ButtonWithIconProps = {
    disabled:boolean;
    onClick: () => void;
    loadingButton:boolean;
    label?:string;
    style?:CSSProperties,
}

export function ButtonWithIcon ({
    disabled,
    onClick,
    loadingButton,
    label = "CONTINUAR",
    style
}:ButtonWithIconProps) {
    const classesBase = stylesBase();

  return (
                <Button disabled={disabled} variant="outlined" className={classesBase.buttonPrimary} onClick={onClick} style={{fontSize:16, ...style}}>
                    {
                        loadingButton ?  <UseAnimations
                        size={40}
                        strokeColor="#fff"
                        animation={loading}
                      /> : label
                    }
                </Button>
  )
}
