import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    height: "100vh",
    textAlign: "center",
  },
  wrapper: {
    padding: 20,
    minWidth: 700,
    [theme.breakpoints.down("sm")]: {
      minWidth: 0,
    },
  },
  containerDialog: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    textAlign: "center",
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  logo: {
    width: "165px",
    borderRadius: 6,
    margin: "10px auto",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      width: "120px",
    },
  },

  firstImage: {
    maxWidth: 461,
    height: 391,
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  containerHeader: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },

  containerFooter: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  containerForm: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  customInput: {
    border: 0,
    borderColor: "none",
    background: "#eee",
    marginRight: 22,
    outline: "none",
    height: 50,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  customButton: {
    minHeight: 50,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
}));
