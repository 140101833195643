import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  title: {
    fontSize: 45,
    lineHeight: "100%",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    fontWeight: 900,
    marginTop: 0,
    marginBottom: 15,
    fontFamily: "poppins, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
      textAlign: "center",
    },
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    color: "var(--dark)",
    marginTop: 0,
    marginBottom: 20,
    fontFamily: "poppins, sans-serif",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  textSecondary: {
    fontSize: 13,
    fontWeight: 500,
    color: "var(--dark)",
    marginTop: 0,
    marginBottom: 20,
    fontFamily: "poppins, sans-serif",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  colSmCenter: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  button: {
    textTransform: "lowercase",
    borderRadius: 100,
  },

  buttonPrimary: {
    background: "#EB5F3E",
    color: "#fff",
    padding: "5px 50px",
    borderRadius: "4px",
    fontFamily: "Inter, sans-serif",
    border: 0,
    outline: 0,
    minHeight: 50,
    fontSize: 12,
    width: "130px",
    fontWeight: 600,

    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    "&:hover": {
      background: "#bf4529",
    },
  },
  buttonSecondary: {
    background: "#6D6D6D",
    color: "#fff",
    padding: "5px 50px",
    borderRadius: "4px",
    fontFamily: "Inter, sans-serif",
    border: 0,
    outline: 0,
    minHeight: 50,
    fontSize: 12,
    width: "130px",
    fontWeight: 600,

    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 15,
    },
  },
  textTitle: {
    fontFamily: "Inter, sans-serif",
    color: "#2D2A4D",
    fontWeight: 500,
    fontSize: "22px",
    marginBottom: 0,
  },
  textLarge: {
    fontFamily: "Inter, sans-serif",
    color: "#2D2A4D",
    fontWeight: 600,
    fontSize: "20px",
    marginBottom: 0,
  },
  textMedium: {
    fontFamily: "Inter, sans-serif",
    color: "#2D2A4D",
    fontWeight: 400,
    fontSize: "16px",
    marginBottom: 0,
  },
  textSmall: {
    fontFamily: "Inter, sans-serif",
    color: "#2D2A4D",
    fontWeight: 400,
    fontSize: "14px",
    marginBottom: 5,
  },

  textDefault: {
    fontFamily: "Inter, sans-serif",
    color: "#6D6D6D",
    fontWeight: 300,
    fontSize: "14px",
    marginBottom: 0,
  },

  label: {
    fontFamily: "Inter, sans-serif",
    color: "#2D2A4D",
    fontWeight: 500,
    fontSize: "15px",
    marginBottom: 10,
  },

  // Input custom
  customInput: {
    border: 0,
    fontFamily: "Inter, sans-serif",
    marginRight: 22,
    outline: "none",
    flex: 1,
    fontSize: 16,
    color: "#333",
    height: 50,
    padding: 13,
    borderRadius: 5,
    background: "#EAEAEA",
  },
}));
