
import { useCallback, useEffect, useMemo, useState } from "react";
import {Button, useMediaQuery} from "@material-ui/core";
import styles from '../../../assets/styles/components/posCheckoutCreateAccount';
import stylesBase from '../../../assets/styles/components/base';
import stylesSteps from '../../../assets/styles/components/stepsCheckout';
import ControlSteps from "./ControlSteps";
import { api } from "../../../services/api";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { API_URL } from "../../../pages/PosCheckout";
import { usePostCheckout } from "../../../hooks/PosCheckoutContext";
import { ButtonWithIcon } from "../buttonWithIcon";
import { CustomInput } from "../customInput";
import axios from "axios";

export default function CreateAboutYou ({field, form,...props}: any) {
    const [loading, setLoading] = useState(false);
    const classes = styles();
    const classesBase = stylesBase();
    const classesSteps = stylesSteps();
    const isMobile = useMediaQuery('(max-width: 960px)');
    const {handleError, handleStep} = usePostCheckout();

    const [typePassword, setTypePassword] = useState(true);
    const [confirmTypePassword, setConfirmTypePassword] = useState(true);

    const {currentStep,totalSteps } = props;


    useEffect(()=>{
        handleStep(1);
    }, [])

    const memoValidateErrors = useMemo(() => {
        const {
            username,
            password,
            passwordConfirmation
        } = form.values;
        if(!username  || !password || passwordConfirmation.length !== password.length) return true;
        if(form.errors.passwordConfirmation) return true;
        return false;
    }, [form]);

    const CustomErroMessage = useCallback(({field}:any) => {
        return (form.errors[field] && form.touched[field]) ? <p className={classesBase.textSmall} style={{color:"#f1003b"}}>{form.errors[field]}</p> : null
    }, [field, form])

    async function validateWhatsApp(){

        if(!form.values.whatsapp) {
            props.nextStep();
            return;
        }

        setLoading(true);
        const valueWhatsApp = form.values.whatsapp.split("+").join("");
        try {
           await api.get(`${API_URL}/customers/check-number/${valueWhatsApp}`)
            props.nextStep();
        }catch(err){
            if(axios.isAxiosError(err)){
                handleError(err.response?.data.mensagem);
            }
            else {
                handleError("Esse número não é um whatsapp válido");
            }
        } finally { 
            setLoading(false);
        }
    }

  return (
        <div className={classesSteps.wrapperStep}>
            <div className={classes.containerDialog}>
                <div className={classes.containerHeader}>
                    <img src="https://app.ihelpchat.com/ihelp-angular/assets/images/logotipo_color.png" alt="IHelp" className={classes.logo}/>
                    <p className={classesBase.textTitle}>Conte mais sobre você</p>
                </div>
            </div>

        <div className={classes.containerForm} >
            <p className={classesBase.label}>Como você se chama?</p>
            <CustomInput
                    name="username"
                    placeholder="Seu nome completo"
                    value={form.values.username}
                    onChange={(e:any)=> {
                        form.setFieldTouched('username');
                        form.setFieldValue("username", e.target.value);
                    }}
            />
            <CustomErroMessage field="username"/>


            <p className={classesBase.label}>Agora, crie sua senha:</p>
            <CustomInput
                    name="password"
                    placeholder="Digite sua senha"
                    hasIconEye
                    value={form.values.password}
                    type={typePassword ? "password" : "text"}
                    changeType={() => setTypePassword(!typePassword)}
                    onChange={(e:any)=> {
                        form.setFieldTouched('password');
                        form.setFieldValue("password", e.target.value);
                    }}
            />
            <CustomErroMessage field="password"/>


            <p className={classesBase.label}>Agora, confirme sua senha:</p>
            <CustomInput
                    name="passwordConfirmation"
                    placeholder="Confirme sua senha"
                    hasIconEye
                    value={form.values.passwordConfirmation}
                    type={confirmTypePassword ? "password" : "text"}
                    changeType={() => setConfirmTypePassword(!confirmTypePassword)}
                    onChange={(e:any)=> {
                        form.setFieldTouched('passwordConfirmation');
                        form.setFieldValue("passwordConfirmation", e.target.value);
                    }}
            />
            <CustomErroMessage field="passwordConfirmation"/>

            <p className={classesBase.label}>E qual seu WhatsApp? (opcional):</p>
   

            <PhoneInput
                    name="whatsapp"
                    international
                    defaultCountry="BR"
                    placeholder="+55 (99) 99999-9999"
                    countryCallingCodeEditable={false}
                    value={form.values.whatsapp}
                    className="phoneInputCustom"
                    onChange={value => {
                        form.setFieldTouched('whatsapp');
                        form.setFieldValue("whatsapp", value)
                    }}
                />

            <CustomErroMessage field="whatsapp"/>

            </div>

        <ControlSteps totalSteps={totalSteps} currentStep={currentStep} />
            <div className={classesSteps.wrapperFooter}>
                <Button 
                variant="outlined"
                style={{width: isMobile ? '100%' : 'auto'}}
                className={classesBase.buttonSecondary}
                onClick={()=> {
                    handleStep(0);
                    props.previousStep();
                }}>
                    VOLTAR
                </Button>
                <ButtonWithIcon 
                    loadingButton={loading}
                    style={{width: isMobile ? '100%' : 'auto'}}
                    disabled={loading || memoValidateErrors}
                    onClick={validateWhatsApp}
                />
            </div>
    </div>
  )
}
