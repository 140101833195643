import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",

    position: "relative",
  },

  headerIcon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",

    position: "absolute",
    right: 0,
    top: 90,
  },

  textLarge: {
    fontFamily: "Inter, sans-serif",
    color: "#2D2A4D",
    fontWeight: 600,
    fontSize: "20px",
  },
  textMedium: {
    fontFamily: "Inter, sans-serif",
    color: "#2D2A4D",
    fontWeight: 400,
    fontSize: "16px",
  },
  textSmall: {
    fontFamily: "Inter, sans-serif",
    color: "#2D2A4D",
    fontWeight: 400,
    fontSize: "14px",
  },

  textDefault: {
    fontFamily: "Inter, sans-serif",
    color: "#6D6D6D",
    fontWeight: 300,
    fontSize: "14px",
  },

  links: {
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
