type ApplicationConfigEnvironments = {
  [key in "checkout" | "sales"]: {
    apiUrl: string;
    apiUrl2?: string;
    successUrls: {
      billet: string;
      creditCard: string;
      pix?: string;
    };
  };
};

interface ApplicationConfig {
  environment: "checkout" | "sales";
  environments: ApplicationConfigEnvironments;
}

export const APPLICATION_CONFIG: ApplicationConfig = {
  environment: "sales",
  environments: {
    checkout: {
      apiUrl: "https://checkout.ihelpchat.com/api/public/api/checkout",
      apiUrl2: "https://apiv3.ihelpchat.com/api/v2",
      // apiUrl2: "https://apihomolog.ihelpchat.com/api/v2",
      // apiUrl2: "http://localhost:5231/api/v2",
      successUrls: {
        billet: "https://site.ihelpchat.com/obrigado-boleto",
        creditCard: "https://site.ihelpchat.com/obrigado-cartao",
      },
    },
    sales: {
      apiUrl: "https://checkout.ihelpchat.com/api/public/api/checkout",
      apiUrl2: "https://apiv3.ihelpchat.com/api/v2",
      // apiUrl2: "http://localhost:5231/api/v2",
      successUrls: {
        billet: "https://ihelp.chat/auto-boleto",
        creditCard: "https://ihelp.chat/auto-cartao",
      },
    },
  },
};
