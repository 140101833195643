import {makeStyles, Theme} from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
    contactContainer: {
        padding: '50px 0',
        [theme.breakpoints.down('sm')]: {
            padding: '30px 0 50px',
        }
    },
    sendBtn: {
        minWidth: 250,
        marginTop: 20,
        marginBottom: 30,
        textTransform: "lowercase",
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 30,
        }
    },
    paymentMethodContainer: {
        display: 'flex',
        alignItems: 'stretch',
        padding: '10px 0',
        width: '100%'
    },
    paymentMethod: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid var(--gray)',
        color: 'var(--black)',
        borderRadius: 6,
        padding: '5px 10px',
        marginRight: 10,
        cursor: 'pointer',
        '&:hover,&.active': {
            borderColor: 'var(--primary)',
            color: 'var(--primary)',
        },
        '& span': {
            fontSize: '.7rem',
            marginLeft: 10,
            fontWeight: 500
        }
    },
    tabContainer: {
        width: '100%',
        border: '1px solid var(--gray)',
        padding: 20
    },
    billetTitle: {
        fontFamily: 'Lato, sans-serif',
        margin: '0 0 10px'
    },
    billetItems: {
        paddingInlineStart: 16,
        '& li': {
            fontSize: '0.9rem',
        }
    }
}))
